import React, { useEffect, useState } from "react";
import { Grid, IconButton, MenuItem, TextField } from "@mui/material";
import { OneProduct } from "../OneProduct/OneProduct";
import { useMediaQuery } from "react-responsive";
import { serverUrl } from "../../consts/serverUrl";
import useStyles from "./styles";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { useAuth } from "../../contexts/AuthProvider";

export const ShopPage = ({
  page,
  setPage,
  selectedProduct,
  setSelectedProduct,
  productList,
  navbarHeight,
  previousShopScroll,
  setPreviousShopScroll,
  previousPage,
  previousShopCategory,
  setPreviousShopCategory,
}) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [productsRetrieved, setProductsRetrieved] = useState(false);
  const [categories, setCategories] = useState({});
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [searchQuery, setSearchQuery] = useState("");

  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const classes = useStyles();

  useEffect(() => {
    const handleScroll = () => {
      if (productsRetrieved) setPreviousShopScroll(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [productsRetrieved]);

  const fetchCategories = async () => {
    let categories = {};
    try {
      categories = await fetch(`${serverUrl}/categories`).then((res) =>
        res.json()
      );
      if (categories.length > 0) setCategories(categories);
    } catch (err) {
      alert(err.message);
    }
  };

  const handleMenuToggle = () => {
    setMenuOpen(!isMenuOpen);
  };
  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setMenuOpen(false);
    setPreviousShopCategory(category);
  };
  useEffect(() => {
    const retrieveCategories = async () => {
      await fetchCategories().then((_) => {
        setProductsRetrieved(true);
      });
    };

    retrieveCategories();
  }, [setCategories]);

  useEffect(() => {
    window.scrollTo(0, previousShopScroll);
  }, [productsRetrieved]);

  useEffect(() => {
    if (!productsRetrieved) window.scrollTo(0, 0);
  }, [page]);

  useEffect(() => {
    if (!productsRetrieved && previousPage === "ProductPage")
      setSelectedCategory(previousShopCategory);
  }, []);

  const renderProductList = (categorySelected) => {
    if (productList && productList.length > 0) {
      const filteredProductList = productList.filter((product) =>
        product.name.toLowerCase().includes(searchQuery.toLowerCase())
      );

      const sortedProductList = filteredProductList.slice().sort((a, b) => {
        // Check if a.order and b.order are present, and fallback to 0 if they are not present
        const orderA = a.order ?? 0;
        const orderB = b.order ?? 0;

        return orderA - orderB; // Will work fine as both are strings now
      });

      if (categorySelected !== "All") {
        return sortedProductList.map(
          (product) =>
            product.category === categorySelected && (
              <Grid item key={product.productId}>
                <OneProduct
                  product={product}
                  setPage={setPage}
                  setSelectedProduct={setSelectedProduct}
                />
              </Grid>
            )
        );
      } else {
        return sortedProductList.map((product) => (
          <Grid item key={product.productId}>
            <OneProduct
              product={product}
              setPage={setPage}
              setSelectedProduct={setSelectedProduct}
            />
          </Grid>
        ));
      }
    } else {
      return <div>Nu există produse de afișat</div>;
    }
  };

  return !productsRetrieved ? (
    <div className={classes.loaderBody}>
      <img src="/favicon.ico" className={classes.loaderImage}></img>
    </div>
  ) : (
    <main>
      <div className={classes.shopBackground}>
        <div
          style={{
            height: navbarHeight,
            backgroundColor: "rgba(0, 0, 0, 0.9)",
          }}
        />

        <div className={classes.backgroundDarkHolderTitle}>
          <div style={{ fontSize: "4vh", fontWeight: "bold" }}>
            PRODUSELE NOASTRE
          </div>
          <br />
          <div>Căutare produse</div>
          <TextField
            margin="normal"
            value={searchQuery}
            onChange={(e) => {
              if (selectedCategory !== "All") setSelectedCategory("All");
              setSearchQuery(e.target.value);
            }}
            style={{
              backgroundColor: "black",
              border: "2px solid",
              borderRadius: "8px",
              height: "5vh",
            }}
            InputLabelProps={{
              style: {
                color: "gold",
              },
            }}
            InputProps={{
              style: {
                height: "5vh",
                color: "#bcab79",
              },
            }}
            placeholder="Denumire produs"
          />
          <br />
          <div>
            {isMobile && (
              <Grid container className={classes.selectMenuContainerMobile}>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <br />
                  <div style={{ color: "#bcab79" }}>
                    {isMenuOpen ? "Închide categorii" : "Categorii"}
                  </div>
                  <IconButton onClick={handleMenuToggle}>
                    {isMenuOpen ? (
                      <CloseIcon className={classes.toggleButton} />
                    ) : (
                      <div>
                        <MenuIcon className={classes.toggleButton} />
                      </div>
                    )}
                  </IconButton>

                  {isMenuOpen && (
                    <div>
                      <MenuItem onClick={() => handleCategoryChange("All")}>
                        <h4
                          style={{
                            fontWeight:
                              selectedCategory === "All" ? "bold" : "normal",
                            textDecoration:
                              selectedCategory === "All" ? "underline" : "none",
                          }}
                        >
                          Toate
                        </h4>
                      </MenuItem>
                      {Object.keys(categories).map((category) => (
                        <MenuItem
                          onClick={() =>
                            handleCategoryChange(
                              categories[category].categoryName
                            )
                          }
                        >
                          <h4
                            style={{
                              fontWeight:
                                selectedCategory ===
                                categories[category].categoryName
                                  ? "bold"
                                  : "normal",
                              textDecoration:
                                selectedCategory ===
                                categories[category].categoryName
                                  ? "underline"
                                  : "none",
                            }}
                          >
                            {categories[category].categoryName}
                          </h4>
                        </MenuItem>
                      ))}
                      <MenuItem
                        onClick={() =>
                          window.open("https://vinshop.ro", "_new")
                        }
                      >
                        <h4
                          style={{
                            fontWeight: "normal",
                          }}
                        >
                          <>Frizzante, Vin, 0% Alcool</>
                          <br />
                          <div style={{ fontStyle: "italic" }}>
                            Pe vinshop.ro
                          </div>
                        </h4>
                      </MenuItem>
                    </div>
                  )}
                  {!isMenuOpen && (
                    <div>
                      <br />
                      <div>
                        Acum vezi:{" "}
                        {selectedCategory === "All"
                          ? "Toate produsele"
                          : selectedCategory}
                      </div>
                    </div>
                  )}
                </Grid>
              </Grid>
            )}
          </div>
          {!isMobile && (
            <Grid container className={classes.selectMenuContainerDesktop}>
              <Grid
                item
                onClick={() => handleCategoryChange("All")}
                xs={2}
                sm={2}
                md={2}
                lg={2}
              >
                <h4
                  style={{
                    fontWeight: selectedCategory === "All" ? "bold" : "normal",
                    textDecoration:
                      selectedCategory === "All" ? "underline" : "none",
                  }}
                >
                  Toate
                </h4>
              </Grid>
              {Object.keys(categories).map((category) => (
                <Grid
                  item
                  onClick={() =>
                    handleCategoryChange(categories[category].categoryName)
                  }
                  xs={2}
                  sm={2}
                  md={2}
                  lg={2}
                  key={categories[category].categoryId}
                >
                  <h4
                    style={{
                      fontWeight:
                        selectedCategory === categories[category].categoryName
                          ? "bold"
                          : "normal",
                      textDecoration:
                        selectedCategory === categories[category].categoryName
                          ? "underline"
                          : "none",
                    }}
                  >
                    {categories[category].categoryName}
                  </h4>
                </Grid>
              ))}
              <Grid
                item
                href="https://vinshop.ro"
                onClick={() => window.open("https://vinshop.ro", "_new")}
                xs={2}
                sm={2}
                md={2}
                lg={2}
                key="vinshop"
              >
                <h4
                  style={{
                    fontWeight: "normal",
                  }}
                >
                  <>Frizzante, Vin, 0% Alcool</>
                  <br />
                  <div style={{ fontStyle: "italic" }}>Pe vinshop.ro</div>
                </h4>
              </Grid>
            </Grid>
          )}
        </div>
        <div className={classes.brHolder}></div>
        <div className={classes.backgroundDarkHolderProducts}>
          <Grid container className={classes.productsGridContainer}>
            {renderProductList(selectedCategory)}
          </Grid>
        </div>
        <div className={classes.brHolder}></div>
      </div>
    </main>
  );
};
