import { makeStyles } from "@mui/styles";
// import backgroundImage from "/main-background.jpg";

export default makeStyles((theme) => ({
  // toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    // backgroundColor: theme.palette.background.default,
    // padding: theme.spacing(3),
    fontFamily: "Montserrat, sans-serif",
  },
  root: {
    colorScheme: "dark",
    flexGrow: 1,
  },
  toolbarFooter: {
    height: "64px",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    fontWeight: "bold",
    color: "#bcab79",
    maxWidth: "95vw",
    paddingLeft: "2%",
  },
  modalBF: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    fontWeight: "bold",
    color: "#bcab79",
    maxWidth: "95vw",
    // paddingLeft: "2%",
  },
  modalContent: {
    backgroundColor: "#0a0808",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
    padding: 16,
    outline: "none",
    border: "1px 1px 1px 1px",
    borderColor: "#bcab79",
    borderStyle: "solid",
    borderRadius: "8px",
    fontFamily: "Montserrat, sans-serif",
    maxWidth: "70vw",
  },
  promoModalContent: {
    backgroundColor: "#0a0808",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
    padding: 0,
    outline: "none",
    border: "1px 1px 1px 1px",
    borderColor: "#bcab79",
    borderStyle: "solid",
    borderRadius: "8px",
    fontFamily: "Montserrat, sans-serif",
    maxWidth: "70vw",
  },
  modalBFContent: {
    backgroundColor: "#0a0808",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
    padding: 13,
    outline: "none",
    border: "1px 1px 1px 1px",
    borderColor: "#bcab79",
    borderStyle: "solid",
    borderRadius: "8px",
    fontFamily: "Montserrat, sans-serif",
    maxWidth: "70vw",
  },
  modalButton: {
    backgroundColor: "#bcab79",
    border: "none",
    fontFamily: "Montserrat, sans-serif",
    "&:hover": {
      backgroundColor: "#0a0808",
      color: "#bcab79",
    },
  },
  promoModalCloseBtn: {
    position: "relative",
    left: "45%",
    fontWeight: "bold",
    cursor: "pointer",
  },
  modalLogo: { width: "5vw" },
  "@media (max-width: 768px)": {
    modalLogo: { width: "15vw" },
  },
  promoModalBanner: {
    height: "75vh",
    borderRadius: "8px",
    "@media (max-width: 768px)": {
      height: "60vh",
    },
  },
}));
