// general helpers

import React from "react";
import { emailRegexTest, romanianPhoneNumberRegexTest } from "./functions";
import { welcomePageTexts } from "./welcomePageTexts";
import { MenuItem, Select } from "@mui/material";
import { PSDark, PSRed } from "./styleConsts";

export const ignoredCategories = [
  "Frizzante",
  "Vin",
  "0% Alcool",
  "Oferte",
  "Gift Boxes",
];

export const checkEmptyDictionary = (dictionary) => {
  return Object.keys(dictionary).length === 0;
};

export const scrollToTop = () => {
  window.scrollTo(0, 0);
};

export const countySelector = (
  shippingOrBilling,
  county,
  setCounty,
  validationError
) => {
  return (
    <Select
      style={{
        border: validationError ? `1px solid ${PSRed}` : `1px solid ${PSDark}`,
        borderRadius: "10px",
        marginBottom: "10px",
        color: PSDark,
        paddingLeft: "5px",
        paddingRight: "5px",
        width: "13rem",
        height: "2.5rem",
      }}
      disableUnderline
      id={`${shippingOrBilling}County`}
      name="judete"
      value={county}
      onChange={(e) => setCounty(e.target.value)}
    >
      <MenuItem value="0">Alege Judet</MenuItem>
      <MenuItem value="Alba">Alba</MenuItem>
      <MenuItem value="Arad">Arad</MenuItem>
      <MenuItem value="Arges">Arges</MenuItem>
      <MenuItem value="Bacau">Bacau</MenuItem>
      <MenuItem value="Bihor">Bihor</MenuItem>
      <MenuItem value="Bistrita Nasaud">Bistrita Nasaud</MenuItem>
      <MenuItem value="Botosani">Botosani</MenuItem>
      <MenuItem value="Brasov">Brasov</MenuItem>
      <MenuItem value="Braila">Braila</MenuItem>
      <MenuItem value="Bucuresti">Bucuresti</MenuItem>
      <MenuItem value="Buzau">Buzau</MenuItem>
      <MenuItem value="Caras Severin">Caras Severin</MenuItem>
      <MenuItem value="Calarasi">Calarasi</MenuItem>
      <MenuItem value="Cluj">Cluj</MenuItem>
      <MenuItem value="Constanta">Constanta</MenuItem>
      <MenuItem value="Covasna">Covasna</MenuItem>
      <MenuItem value="Dambovita">Dambovita</MenuItem>
      <MenuItem value="Dolj">Dolj</MenuItem>
      <MenuItem value="Galati">Galati</MenuItem>
      <MenuItem value="Giurgiu">Giurgiu</MenuItem>
      <MenuItem value="Gorj">Gorj</MenuItem>
      <MenuItem value="Harghita">Harghita</MenuItem>
      <MenuItem value="Hunedoara">Hunedoara</MenuItem>
      <MenuItem value="Ialomita">Ialomita</MenuItem>
      <MenuItem value="Iasi">Iasi</MenuItem>
      <MenuItem value="Ilfov">Ilfov</MenuItem>
      <MenuItem value="Maramures">Maramures</MenuItem>
      <MenuItem value="Mehedinti">Mehedinti</MenuItem>
      <MenuItem value="Mures">Mures</MenuItem>
      <MenuItem value="Neamt">Neamt</MenuItem>
      <MenuItem value="Olt">Olt</MenuItem>
      <MenuItem value="Prahova">Prahova</MenuItem>
      <MenuItem value="Satu Mare">Satu Mare</MenuItem>
      <MenuItem value="Salaj">Salaj</MenuItem>
      <MenuItem value="Sibiu">Sibiu</MenuItem>
      <MenuItem value="Suceava">Suceava</MenuItem>
      <MenuItem value="Teleorman">Teleorman</MenuItem>
      <MenuItem value="Timis">Timis</MenuItem>
      <MenuItem value="Tulcea">Tulcea</MenuItem>
      <MenuItem value="Vaslui">Vaslui</MenuItem>
      <MenuItem value="Valcea">Valcea</MenuItem>
      <MenuItem value="Vrancea">Vrancea</MenuItem>
    </Select>
  );
};

export const formDataCheck = (
  billingOrShipping,
  country,
  county,
  city,
  addressLine,
  email,
  firstName,
  lastName,
  mobilePhone,
  classes
) => {
  let wrongFields = false;
  if (!country) {
    document
      .getElementById(`${billingOrShipping}Country`)
      .classList.add(`${classes.wrongField}`);
    wrongFields = true;
  } else {
    document
      .getElementById(`${billingOrShipping}Country`)
      .classList.remove(`${classes.wrongField}`);
  }
  if (county === "0") {
    document
      .getElementById(`${billingOrShipping}County`)
      .classList.add(`${classes.wrongField}`);
    wrongFields = true;
  } else {
    document
      .getElementById(`${billingOrShipping}County`)
      .classList.remove(`${classes.wrongField}`);
  }
  if (!city) {
    document
      .getElementById(`${billingOrShipping}City`)
      .classList.add(`${classes.wrongField}`);
    wrongFields = true;
  } else {
    document
      .getElementById(`${billingOrShipping}City`)
      .classList.remove(`${classes.wrongField}`);
  }
  if (!addressLine) {
    document
      .getElementById(`${billingOrShipping}Address`)
      .classList.add(`${classes.wrongField}`);
    wrongFields = true;
  } else {
    document
      .getElementById(`${billingOrShipping}Address`)
      .classList.remove(`${classes.wrongField}`);
  }
  if (!email || !emailRegexTest(email)) {
    document
      .getElementById(`${billingOrShipping}EmailInput`)
      .classList.add(`${classes.wrongField}`);
    wrongFields = true;
  } else {
    document
      .getElementById(`${billingOrShipping}EmailInput`)
      .classList.remove(`${classes.wrongField}`);
  }
  if (!firstName) {
    document
      .getElementById(`${billingOrShipping}FirstNameInput`)
      .classList.add(`${classes.wrongField}`);
    wrongFields = true;
  } else {
    document
      .getElementById(`${billingOrShipping}FirstNameInput`)
      .classList.remove(`${classes.wrongField}`);
  }
  if (!lastName) {
    document
      .getElementById(`${billingOrShipping}LastNameInput`)
      .classList.add(`${classes.wrongField}`);
    wrongFields = true;
  } else {
    document
      .getElementById(`${billingOrShipping}LastNameInput`)
      .classList.remove(`${classes.wrongField}`);
  }
  if (!mobilePhone || !romanianPhoneNumberRegexTest(mobilePhone)) {
    document
      .getElementById(`${billingOrShipping}PhoneInput`)
      .classList.add(`${classes.wrongField}`);
    wrongFields = true;
  } else {
    document
      .getElementById(`${billingOrShipping}PhoneInput`)
      .classList.remove(`${classes.wrongField}`);
  }
  // if (!CNP || !romanianCNPRegexTest(CNP) || !validateCNP(CNP) || !isOver18(CNP))
  //   document
  //     .getElementById(`${billingOrShipping}CNP`)
  //     .classList.add(`${classes.wrongField}`);
  // else {
  //   document
  //     .getElementById(`${billingOrShipping}CNP`)
  //     .classList.remove(`${classes.wrongField}`);
  // }
  return wrongFields;
};

export const removeDiacritics = (text) => {
  return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

export const findItemByTitle = (title) => {
  // Iterate through the objects in welcomePageTexts
  for (const sectionKey in welcomePageTexts) {
    const section = welcomePageTexts[sectionKey];

    // Check if the current section has the provided title
    if (section.title === title) {
      return section; // Return the matching section
    }

    // If the current section has nested items, check them as well
    for (const itemKey in section) {
      const item = section[itemKey];

      if (item.title === title) {
        return item; // Return the matching item
      }
    }
  }

  return null; // Return null if no match is found
};
