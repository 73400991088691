import React from "react";
import LandingPage from "./components/LandingPage/LandingPage";
import { AuthContextProvider } from "./contexts/AuthProvider";
import "./App.css";
import { AdminAuthContextProvider } from "./contexts/AdminAuthProvider";

const App = () => {
  return (
    <AuthContextProvider>
      <AdminAuthContextProvider>
        <LandingPage></LandingPage>
      </AdminAuthContextProvider>
    </AuthContextProvider>
  );
};

export default App;
